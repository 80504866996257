@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body a {
    text-decoration: none;
    color: black;
    /* overflow: auto; */
    /* background-color: white; */
}

.leaflet-container {
    position: relative;
    width: 100%;
    height: 100%;
}